import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";

function EnviromentalComponent() {
  return (
    <>
      <Container className="mb-3">
        <Row>
          <Col md={3}>
            <Card className="mb-5">
              <Card.Header style={{ backgroundColor: "#171347" }}>
                <h6 className="text-white">Corporate Social Responsibility</h6>
              </Card.Header>
              <Card.Body>
                <p
                  style={{
                    textOverflow: "ellipsis",
                    maxLines: 2,
                    maxWidth: "100%",
                  }}
                >
                  Our organization recognizes its responsibility to contribute
                  positively to society, the environment, and the well-being of
                  our stakeholders....
                </p>

                <a
                  href={require("../webs/Corporate Social Responsibility - Webs.doc revised.pdf")}
                  className="text-warning"
                >
                  Read More... <FeatherIcon icon="download" className="pt-2" />
                </a>
              </Card.Body>
            </Card>
          </Col>

          <Col md={3}>
            <Card className="mb-5">
              <Card.Header style={{ backgroundColor: "#171347" }}>
                <h6 className="text-white">Environment Management Policy</h6>
              </Card.Header>
              <Card.Body>
                <p
                  style={{
                    textOverflow: "ellipsis",
                    maxLines: 2,
                    maxWidth: "100%",
                  }}
                >
                  Webs for Africa Technologies P/L recognizes the importance of
                  environmental stewardship and commits to responsible practices
                  that contribute to a....
                </p>
                <a
                  href={require("../webs/Environment Management Policy - Webs.doc revised.pdf")}
                  className="text-warning"
                >
                  Read More... <FeatherIcon icon="download" className="pt-2" />
                </a>
              </Card.Body>
            </Card>
          </Col>

          <Col md={3}>
            <Card className="mb-5">
              <Card.Header style={{ backgroundColor: "#171347" }}>
                <h6 className="text-white">Equal Employment Policy</h6>
              </Card.Header>
              <Card.Body>
                <p
                  style={{
                    textOverflow: "ellipsis",
                    maxLines: 2,
                    maxWidth: "100%",
                  }}
                >
                  Webs For Africa Technologies P/L is committed to promoting
                  equal opportunity and diversity in the workplace. We believe
                  in attracting and retaining the...
                </p>
                <a
                  href={require("../webs/Equal Employment Policy - Webs v. 1.doc revised.pdf")}
                  className="text-warning"
                >
                  Read More... <FeatherIcon icon="download" className="pt-2" />
                </a>
              </Card.Body>
            </Card>
          </Col>

          <Col md={3}>
            <Card className="mb-5">
              <Card.Header style={{ backgroundColor: "#171347" }}>
                <h6 className="text-white">Health and Safety</h6>
              </Card.Header>
              <Card.Body>
                <p
                  style={{
                    textOverflow: "ellipsis",
                    maxLines: 2,
                    maxWidth: "100%",
                  }}
                >
                  Webs for Africa Technologies (Private) Limited considers the
                  health, safety, and welfare at work of the employee to be an
                  essential part of being a...
                </p>

                <a
                  href={require("../webs/Health and Safety - Webs v 1.0.doc revised.pdf")}
                  className="text-warning"
                >
                  Read More... <FeatherIcon icon="download" className="pt-2" />
                </a>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default EnviromentalComponent;
